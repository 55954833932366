
import { Options, Vue } from 'vue-class-component';
import EquipmentForm from '@/components/forms/EquipmentForm.vue';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import OperationResult from '@/components/layout/OperationResult.vue';

@Options({
    components: {
        EquipmentForm, Breadcrumb, OperationResult
    },
    data(){
        return {
            category: {},
            form: {},
            type: {},
            result: {
                show: false,
                title: '',
                duration: 0,
                toTitle: '',
                status: 'SUCCESS'
            },
            loading: false
        }
    },
    mounted(){
        this.getCategory();
        this.getEquipment();
    },
    methods:{
        getCategory(){
            this.$api.xrCategoryShow(Number(this.$route.params.id)).then((res: any) => {
                console.log(res)
                this.category = {
                    id: res.data.id,
                    title: res.data.title,
                    type: res.data.type
                };
            })
        },
        getEquipment(){
            this.$api.xrEquipmentShow(Number(this.$route.params.equipment_id)).then((res: any) => {
                let status: object = {},
                    permissions: object = {},
                    content = '',
                    published_at = new Date(res.data.published_at);
                
                if(res.data.type_id === 1){
                    this.type = {
                        label: '啤酒酿造设备',
                        value: 1
                    }
                } else if(res.data.type_id === 2){
                    this.type = {
                        label: '发酵罐',
                        value: 2
                    }
                } else if(res.data.type_id === 3){
                    this.type = {
                        label: '定制不锈钢水箱',
                        value: 3
                    }
                } else if(res.data.type_id === 4){
                    this.type = {
                        label: '灌装线',
                        value: 4
                    }
                } else if(res.data.type_id === 5){
                    this.type = {
                        label: '啤酒桶',
                        value: 5
                    }
                } else if(res.data.type_id === 6){
                    this.type = {
                        label: '其他配件',
                        value: 6
                    }
                }

                if(res.data.status === 'published'){
                    status = {
                        label: '发布',
                        value: 'published'
                    }
                } else if(res.data.status === 'draft'){
                    status = {
                        label: '草稿',
                        value: 'draft'
                    }
                } else {
                    status = {
                        label: '回收站',
                        value: 'deleted'
                    }
                }

                if(res.data.permissions === 'public'){
                    permissions = {
                        label: '公开',
                        value: 'public'
                    }
                } else {
                    permissions = {
                        label: '私有',
                        value: 'private'
                    }
                }

                this.form = res.data;
                this.form.status = status;
                this.form.permissions = permissions;
                this.form.published_at = {
                    date: {
                        year: published_at.getFullYear(),
                        month: published_at.getMonth()+1 < 10 ? `0${published_at.getMonth()+1}` : published_at.getMonth()+1,
                        day: published_at.getDate() < 10 ? `0${published_at.getDate()}` : published_at.getDate()
                    },
                    time: {
                        hour: published_at.getHours() < 10 ? `0${published_at.getHours()}` : published_at.getHours(),
                        minute: published_at.getMinutes() < 10 ? `0${published_at.getMinutes()}` : published_at.getMinutes(),
                        second: published_at.getSeconds() < 10 ? `0${published_at.getSeconds()}` : published_at.getSeconds()
                    }
                }

            })
        },
        submit(e: any){
            this.loading = true;
            this.$api.xrEquipmentUpdate(Number(e.id), e).then((res: any) => {
                this.loading = false;
                if(res.msg === 'success'){
                    this.result = {
                        show: true,
                        title: '设备信息更新成功',
                        duration: 0,
                        status: 'SUCCESS'
                    }
                } else {
                    this.result = {
                        show: true,
                        title: '设备信息更新失败，接口报错，请联系技术人员检查',
                        duration: 0,
                        status: 'ERROR'
                    }
                }
            })
        }
    }
})

export default class EquipmentCreate extends Vue {}
